.post-modal-slide > post-modal-slide.swiper-pagination {
  bottom: 80px !important;
}

.post-modal-slide .swiper-pagination {
  margin-bottom: 60px;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: rgba(255, 255, 255, 0.8);
}

.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-button-prev,
.swiper-button-next {
  width: 36px !important;
  height: 36px !important;
  border-radius: 24px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  color: #fff !important;
  font-size: 18px;
}

.swiper-button-prev:after {
  text-indent: -2px;
}
.swiper-button-next:after {
  text-indent: 2px;
}
